import React from 'react'
import { CreativeModalLeftContent } from '../styles'
import { OutlineBtn } from '../../../../styles/buttons'
import Confirm from '../../../../components/confirm/Confirm'
import ModalApproval from '../ModalApproval'
import AcceptModal from '../../../../pages/build/modals/AcceptModal'
import ModalRejection from '../ModalRejection'
import { useCreativeActionState } from '../../hooks/useCreativeActionState'
import { XS } from '../../../../styles/typography'
import CreativeStatusToggle from '../../components/creativeStatusToggle'
import BulkEditModal from '../../../../components/modal/bulkEdit/BulkEditModal'
import DisplayMediaV2 from '../DisplayMediaV2'
import useExperiment from '../../../../hooks/useExperiment'
import { EXPERIMENTS } from '../../../../helpers/experimentHelpers'
import { isSecondaryLocation } from '../../../../types/location'
import { isPostEntity } from '../../../../helpers/creative.util'


const CreativeActions = () => {
    const _S = useCreativeActionState()
    const blockPostsOnSecondaries = useExperiment(EXPERIMENTS.BLOCK_POSTS_ON_SECONDARIES)
    const switchCenterPhaseTwo = useExperiment(EXPERIMENTS.PRODUCT_SWITCH_PHASE_TWO)
    const isCreativePost = isPostEntity(_S.creative)
    const creativeActionCopyType = (isCreativePost && switchCenterPhaseTwo.active) ? 'Businesses' : 'Campaigns'
    const hideEditButton = blockPostsOnSecondaries.active &&  _S.location && isSecondaryLocation( _S.location) && isCreativePost

    return (
        <CreativeModalLeftContent style={{ visibility: _S.BulkEditor.options ? 'hidden' : 'visible' }}
        >
            <div className='media-container'>
                    <DisplayMediaV2 creative={_S.creative} location={_S.location}/>
            </div>
            {
                _S.showToggle &&
                <div className='pause-action-container'>
                    <XS bold={!_S.isPaused}>{_S.isFolder ? 'Cloning Enabled' : 'Running' }</XS>
                    <CreativeStatusToggle
                        size={'md'}
                        isOn={_S.isPaused}
                        handleToggle={_S.handlePlayPause}
                    />
                    <XS bold={_S.isPaused}>{_S.isFolder ? 'Cloning Disabled' : 'Paused'}</XS>
                </div>
            }
            {
                _S.needsApproval &&
                <div className='action-btn-container'>
                    <ModalApproval
                        creative={_S.creative}
                        refreshCreatives={_S.refreshCreatives}
                        onClose={_S.onClose}
                    />
                    <ModalRejection
                        creative={_S.creative}
                        refreshCreatives={_S.refreshCreatives}
                        onClose={_S.onClose}
                    />
                </div>
            }
            <div className='action-btn-container'>
                {
                    _S.isMagicEntity && !_S.isFolder &&
                    <OutlineBtn onClick={() => _S.setIsAccepting(true)}>Accept</OutlineBtn>
                }
                {
                    !hideEditButton &&
                    <OutlineBtn onClick={() => _S.editCreative({ creative: _S.creative, overrideLocation: _S.location })}>Edit</OutlineBtn>
                }
                <OutlineBtn onClick={() => _S.setIsCloneModalOpen((prev: boolean) => !prev)}>Clone</OutlineBtn>
                <OutlineBtn onClick={() => _S.setIsDeleteWarningOpen(true)}>Delete</OutlineBtn>
            </div>
            <div className='action-btn-container'>
                <OutlineBtn onClick={_S.handleManageClones}>View All Clones</OutlineBtn>
                <OutlineBtn onClick={_S.onDeleteAll}>Delete from All {creativeActionCopyType}</OutlineBtn>
                {
                    _S.isMagicEntity && !_S.needsApproval &&
                    <OutlineBtn onClick={_S.onAcceptAll}> Accept for All {creativeActionCopyType}</OutlineBtn>
                }
                {
                    _S.isDeployedAd && !_S.needsApproval &&
                    <>
                        <OutlineBtn onClick={_S.onPauseAll}>Pause for All Campaigns</OutlineBtn>
                        <OutlineBtn onClick={_S.onResumeAll}>Resume for All Campaigns</OutlineBtn>
                    </>
                }
            </div>
            { // * Deletion
                _S.isDeleteWarningOpen &&
                <Confirm
                    onClose={() => _S.setIsDeleteWarningOpen(false)}
                    text={`Are you sure you want to delete this ${_S.creativeKindLabel}?`}
                    reject={{
                        text: `Delete`,
                        callback: () => {
                            _S.deleteCreative({ creative: _S.creative, suppressToast: false }).then(() => {
                                _S.setIsDeleteWarningOpen(false)
                                _S.refreshCreatives?.();
                            }).catch(() => {
                                _S.setIsDeleteWarningOpen(false)
                            })
                        }
                    }}
                    cancel={{
                        text: 'Cancel',
                        callback: () => _S.setIsDeleteWarningOpen(false)
                    }}
                />
            }
            { // * Resume Warning
                _S.isResumeWarningOpen &&
                <Confirm
                    onClose={_S.closeResumePopup}
                    text={_S.isFolder ? `This will allow this creative to be cloned over to new onboarding campaigns. Continue?` : 'Are you sure? This will resume your ad.'}
                    confirm={{
                        text: 'Confirm',
                        callback: () => {
                            _S.resumeCreative({
                                creative: _S.creative,
								// @ts-ignore
                                overrideLocation: _S.location,
                                suppressToast: false,
                            }).then(() => {
                                _S.setIsResumeWarningOpen(false);
                                _S.refreshCreatives?.()
                            });
                        }
                    }}
                    cancel={{
                        text: 'Cancel',
                        callback: _S.closeResumePopup
                    }}
                />
            }
            { // * Pause Warning
                _S.isPauseWarningOpen &&
                <Confirm
                    onClose={_S.closePausePopup}
                    text={_S.isFolder ? `Are you sure? This will prevent this creative from being cloned over to new onboarding campaigns.` : 'Are you sure? This will pause your ad.'}
                    confirm={{
                        text: 'Confirm',
                        callback: () => {
                            _S.pauseCreative({
                                creative: _S.creative, overrideLocation: _S.location
                            }).then(() => {
                                _S.setIsPauseWarningOpen(false)
                                _S.refreshCreatives?.()
                            })
                        }
                    }}
                    cancel={{
                        text: 'Cancel',
                        callback: _S.closePausePopup
                    }}
                />
            }
            { // * Bulk Actions
                _S.BulkEditor.options &&
                <BulkEditModal
                    onClose={() => {
                        _S.BulkEditor.setOptions(null);
                        _S.onClose();
                    }}
                    creative={_S.creative}
                    bulkEditor={_S.BulkEditor}
                    // * pass true here since more than just the selected creative may have been updated
                    refreshCreatives={() => _S.refreshCreatives()}
                />
            }
            {
                _S.isAccepting &&
                <AcceptModal
                    sample={_S.creative}
                    locations={[_S.location]}
                    onClose={_S.setIsAccepting.bind(null, false)}
                    refreshCreatives={_S.refreshCreatives}
                    closeMainModal={_S.onClose}
                />
            }
        </CreativeModalLeftContent>
    )
}

export default CreativeActions