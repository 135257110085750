//* Experiment naming should follow a key of SNAKE_CASE and a value of fcc-lower-case-kebab (prepend with fcc-)
//* Experiments should be assigned to the owner

// Types for key and value formatting
type UpperSnakeCase = `${Uppercase<string>}_${Uppercase<string>}` | `${Uppercase<string>}`;

// Ensure values are in `fcc-lower-case-kebab` or `be-lower-case-kebab` format (e.g., `fcc-example-case` or `be-example-case`)
//! We want to eventually deprecate the `be-lower-case-kebab` format. Update this type when it is completely deprecated
type LowerKebabCase = `fcc-${Lowercase<string>}` | `be-${Lowercase<string>}`;

type ExperimentType = {
    [key in UpperSnakeCase]: LowerKebabCase;
};

//* Owners of Experiment
const ANTHONY_EXP = {

} satisfies ExperimentType

const ANUJ_EXP = {
    ENTER_BUSINESS_DETAILS: 'fcc-enter-business-details',
} satisfies ExperimentType

const DANIEL_EXP = {
    SEARCH_TERM_REPORT: 'fcc-search-term-report',
    GOOGLE_TV_TARGETING: 'fcc-google-tv-targeting',
} satisfies ExperimentType

const NICOLE_EXP = {
    COUNT_CHARACTER_BYTES: 'fcc-count-character-bytes',
    REPUTATION_LISTINGS_COPY: 'fcc-reputation-listings-copy',
    BLOCK_POSTS_ON_SECONDARIES: 'fcc-block-posts-on-secondaries',
    LOCATION_PERFORMANCE_REPORT: 'fcc-location-performance-report',
} satisfies ExperimentType

const PRANJAL_EXP = {
    NEW_GEO_RADII_UI:'fcc-new-geo-radii-ui',
    VISIBILITY_CUE: 'fcc-visibility-cue',
    RM_API_CHANGE: 'fcc-rm-api-change',
} satisfies ExperimentType

const SINAN_EXP = {
    DEMO_RMI_LISTING_MANAGEMENT: 'fcc-demo-rmi-listing-management',
    PRODUCT_SWITCH_SORTING: 'fcc-switch-center-sorting',
    PRODUCT_SWITCH_PHASE_TWO: 'fcc-switch-center-phase-two'
} satisfies ExperimentType

const TAULANT_EXP = {
    CPE_EULERITY_ATTRIBUTION: 'fcc-cpe-eulerity-attribution',
    TOTAL_EVENTS: 'fcc-total-events',
} satisfies ExperimentType

const OTHER_EXP = {
    EMAIL_MARKETING: 'be-fcc-show-email-marketing',
    SMS_MARKETING: 'be-fcc-show-sms-marketing',
    BRAND_THEME_TOOL: "fcc-brand-theme-tool",
} satisfies ExperimentType

//* Experiments that should be hidden from sidebar
const HIDDEN_EXPERIMENTS_FROM_SIDEBAR = new Set<ExperimentName>([
    OTHER_EXP.BRAND_THEME_TOOL,
    OTHER_EXP.EMAIL_MARKETING,
    OTHER_EXP.SMS_MARKETING,
])

export const EXPERIMENTS = {
    ...ANTHONY_EXP,
    ...ANUJ_EXP,
    ...DANIEL_EXP,
    ...NICOLE_EXP,
    ...PRANJAL_EXP,
    ...SINAN_EXP,
    ...TAULANT_EXP,
    ...OTHER_EXP,
} as const

export const SIDEBAR_EXPERIMENTS = Object.values(EXPERIMENTS).filter(exp => !HIDDEN_EXPERIMENTS_FROM_SIDEBAR.has(exp))

export type ExperimentName = typeof EXPERIMENTS[keyof typeof EXPERIMENTS]
