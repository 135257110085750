import React, { useMemo } from 'react'
import { useCreativeContext } from '../CreativeModalV2';
import { gmtToLocaleString } from '../../../../helpers';
import { getNetworkName } from '../../../../helpers/creative.util';
import { CreativeDataContainer, NetworkItem } from '../styles';
import NetworkIcon from '../NetworkIcon';
import { Small } from '../../../../styles/typography';
import { useThemeControl } from '../../../../hooks/useThemeControl';
import { getCloneStatusLabel } from '../utils';
import PrimeTimeAlert from '../../../../pages/build/modals/steps/PeakPostTime/PrimeTimeAlert';
import useExperiment from '../../../../hooks/useExperiment';
import { EXPERIMENTS } from '../../../../helpers/experimentHelpers';

const PostDetailsTable = () => {
    const { creativeStatusColorMap } = useThemeControl()
    const { creative, metadata } = useCreativeContext()
    const switchCenterPhaseTwo = useExperiment(EXPERIMENTS.PRODUCT_SWITCH_PHASE_TWO)

    // Derived properites
    const { isMagicPost, isFolder, creativeDateText, user, location } = metadata
    const { created, extendedText, header, description, instagramCollaborators } = creative

    const shouldShowPeakDate = creative?.peakStartDate  && !creative?.postedDate

    const postDate = useMemo(() => {
        // * Check if exp is active AND the selected creative opt'd for prime post (still has not been posted)
        if (shouldShowPeakDate) return creativeDateText?.start?.split(',')?.slice(0, 2)?.join(',')
        return creativeDateText?.start
    }, [creativeDateText, shouldShowPeakDate])

    return (
        <CreativeDataContainer>
            <table>
                <tbody>
                    <tr>
                        <th><Small semibold>Created</Small></th>
                        <td><Small>{gmtToLocaleString(created)}</Small></td>
                    </tr>
                    {
                        header?.text?.length > 0 && header?.color?.a > 1 &&
                        <tr>
                            <th><Small semibold>Header</Small></th>
                            <td><Small>{header?.text}</Small></td>
                        </tr>
                    }
                    {
                        description?.text?.length > 0 && description?.color?.a > 1 &&
                        <tr>
                            <th><Small semibold>Description</Small></th>
                            <td><Small>{description?.text}</Small></td>
                        </tr>
                    }
                    <tr>
                        <th><Small semibold>Social Text</Small></th>
                        <td><Small>{extendedText || "--not provided--"}</Small></td>
                    </tr>
                    <tr>
                        <th style={{alignContent: 'start'}}><Small semibold>Post</Small></th>
                        <td>
                            <div>
                                <Small>{postDate}</Small>
                                {
                                    shouldShowPeakDate &&
                                    <PrimeTimeAlert />
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th><Small semibold>User</Small></th>
                        <td><Small>{user?.email}</Small></td>
                    </tr>
                    {!isFolder &&
                        <>
                            <tr>
                                <th><Small semibold>Business Name</Small></th>
                                <td><Small>{location?.name}</Small></td>
                            </tr>
                            <tr>
                                <th><Small semibold>{switchCenterPhaseTwo.decide('Business Nickname', 'Campaign Name')}</Small></th>
                                <td><Small>{location?.nickname}</Small></td>
                            </tr>
                        </>
                    }
                    {
                        (isMagicPost || isFolder) &&
                        <tr>
                            <th><Small semibold>Networks</Small></th>
                            <td>
                                {
                                    creative?.targetNetworks.map(network => {
                                        return (
                                            <NetworkItem key={network} style={{ display: 'flex', gap: '8px' }}>
                                                <NetworkIcon network={network} /> <Small>{getNetworkName(network)}</Small>
                                            </NetworkItem>)
                                    })
                                }
                            </td>
                        </tr>
                    }
                    {
                        (instagramCollaborators?.length > 0) &&
                        <tr>
                            <th><Small semibold>Instagram Collaborators</Small></th>
                            <td>{instagramCollaborators?.map(collaborator => `@${collaborator}`).join(", ")} </td>
                        </tr>
                    }
                    {
                        isFolder && getCloneStatusLabel(creative, creativeStatusColorMap)
                    }
                </tbody>
            </table>
        </CreativeDataContainer>
    )
}

export default PostDetailsTable