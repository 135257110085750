
import styled, {css} from "styled-components";
import { decideTextColor, hexToRgba } from "../helpers/colors.util";

export const StyledOptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledOptionItem = styled.label<{
  selected: boolean;
  disabled?: boolean;
}>`
  display: flex;
  background: ${props => props.theme.color.backgroundColor2};
  color: ${props => decideTextColor(props.theme.color.backgroundColor2)};
  border: 1px solid ${props => props.theme.color.accentColor3};
  border-radius: 6px;
  min-height: 72px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  ${({selected}) => css `
    background: ${props => selected && props.theme.color.accentColor4};
    color: ${props => selected && decideTextColor(props.theme.color.accentColor4)};
  `}
  ${({disabled}) => disabled && css `
    pointer-events: none;
    opacity: 0.5;
  `}

  li {
    list-style-type: none;
  }

  input {
    margin-right: 40px;
  }

  svg {
    margin-right: 16px;
  }

`;

export const StyleOptionLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
  justify-content: space-between;

  img, svg {
        width: 24px;
        height: 24px;
        object-fit: cover;
    }
`;

export const StyleOptionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const StyledChannelsOption = styled.div<{ selected?: boolean, disabled?: boolean }>`
  border: 1px solid ${({ theme, selected }) =>
    selected
      ? theme.color.accentColor2
      : hexToRgba(theme.color.greyAccent1, 0.5)};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: ${({ theme, selected }) => selected ? `0 0 0 2px ${hexToRgba(theme.color.accentColor3, 0.25)}` : 'none'};

  ${({disabled}) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  svg {
    height: 16px;
    width: 16px;
  }
`

export const StyledChannelsCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 24px;
  height: 24px;
  cursor: pointer;
  appearance: none; /* Remove default browser styles */
  background-color: white;
  border: 1px solid ${({ theme }) => hexToRgba(theme.color.greyAccent1, 0.5)};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:checked {
    background-color: ${({ theme }) => theme.color.accentColor2};
    border: 2px solid ${({ theme }) => theme.color.accentColor2};
  }

  &:checked::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%) rotate(135deg) scaleY(-1);
  }
`